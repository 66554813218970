import React, { useContext } from "react"
import { StaticImage } from "gatsby-plugin-image"
import FormStateContext from "../../context/FormContext"
import Layout from "../../components/layout"
import * as formConfirmationStyles from "./formConfirmation.module.scss"

const FormConfirmation = ({ pageContext, location }) => {
  const pageTitle = "Form Confirmation"
  const { formConfirmationMessage } = useContext(FormStateContext)

  return (
    <Layout>
      <div className={formConfirmationStyles.heroWrapper}>
        <div
          className={formConfirmationStyles.heroImageWrapper}
          id="inner-header-1-hero"
        >
          <StaticImage
            src="../assets/images/global/page-partials/inner-header-1/factory-TB.png"
            className={formConfirmationStyles.heroImageMB}
            alt=""
          />
          <StaticImage
            src="../assets/images/global/page-partials/inner-header-1/factory-TB.png"
            className={formConfirmationStyles.heroImageTB}
            alt=""
          />
          <StaticImage
            src="../assets/images/global/page-partials/inner-header-1/factory-DT.png"
            className={formConfirmationStyles.heroImageDT}
            alt=""
          />
        </div>
      </div>
      <div
        className={`inner-y-padding inner-container ${formConfirmationStyles.contentWrapper}`}
      >
        <h1>Thank You!</h1>
        <p>
          Thank you for your interest in Rocky Brands. Each request submitted
          does not guarantee a partnership or response. We will contact you if
          there is interest in your submission.
        </p>
      </div>
    </Layout>
  )
}

export default FormConfirmation
